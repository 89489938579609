import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.css'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import FaqItems from "../constants/faqItems.json"

const style = {
    faqHeader: {
        textAlign: "left"
    }
};

class FAQPage extends React.Component {
    faqItems = FaqItems.sort((a, b) => {
        return a.order - b.order;
    })
    render() {
        return (<Layout>
            <SEO title="FAQ" />
            <div className="Site-inner" data-controller="HeaderOverlay" id="yui_3_17_2_1_1538632099177_588">
                <header className="Header Header--bottom Header--overlay">
                    <div className="Header-inner Header-inner--bottom" data-nc-group="bottom">
                        <div data-nc-container="bottom-left">
                        </div>
                        <div data-nc-container="bottom-center">
                        </div>
                        <div data-nc-container="bottom-right">
                            <nav className="Header-nav Header-nav--secondary" data-nc-element="secondary-nav"
                                data-content-field="navigation">
                            </nav>
                        </div>
                    </div>
                </header>
                <div className="Content-outer" id="yui_3_17_2_1_1538632099177_224">
                    <main className="Index" data-collection-id="5b160901758d4658e6571eef" data-controller="IndexFirstSectionHeight, Parallax, IndexNavigation"
                        data-controllers-bound="IndexFirstSectionHeight, Parallax, IndexNavigation">
                        <section id="contact-us" className="Index-page" data-collection-id="5ad765962b6a288cd1072c53"
                            data-parallax-id="5ad765962b6a288cd1072c53" data-edit-main-image="Background">
                            <div className="Index-page-content ">
                                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1528241759086"
                                    id="page-5ad765962b6a288cd1072c53">
                                    <div className="row sqs-row">
                                        <div className="col sqs-col-12 span-12">
                                            <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1528212858018_25259">
                                                <div className="sqs-block-content text-align-left">
                                                    <h2>Frequently Asked Questions</h2>
                                                    <Accordion>
                                                        {this.faqItems.map((item, i) => {
                                                            return <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                                                                        <h3 style={style.faqHeader}><strong>{item.question}</strong></h3>
                                                                    </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey={i}>
                                                                    <Card.Body dangerouslySetInnerHTML={{ __html: item.answer }}></Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        })}
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </Layout>)
    }
}

export default FAQPage
